@use 'media' as *;

$colors: (
	'bg': #f8fcfe,
	'title': #009fe3,
	'week-border': #009fe3,
	'heading': #707070,
	'field-bg': #ffffff,
	'label': #000000,
	'placeholder': #888888,
	'border': #a9a9a9,
	'button-color': #ec712e,
	'close-hover': #e3383b,
	'tag-bg': #c5e4fa4d,
	'scrollbar-track': #e6e6e6,
	'focus-color': #c5e4fa,
	'checkbox-checked-bg': #429edd,
	'checkbox-checked-border': #88bfe5,
	'disabled': #e5e5e5,
	'disabled-bg': #6c757d
);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

@mixin font-style {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	line-height: 1.35;
	letter-spacing: 0.05em;
}

@mixin column {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@mixin row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin transition {
	&,
	&::before,
	&::after {
		transition: 180ms linear;
	}
}

@mixin hover {
	cursor: pointer;
	@include transition;
	&:hover {
		@content;
	}
}

@mixin closeButton($size: 38px) {
	@include column;
	cursor: pointer;
	width: $size;
	height: $size;

	font-size: $size * 0.7;
	font-weight: 400;
	color: map-get($colors, 'border');

	@include hover {
		color: map-get($colors, 'close-hover');
	}
}

@mixin disabledField {
	background-color: map-get($colors, 'disabled');
	pointer-events: none;
	user-select: none;
}

// --------------------------------
// -------  CONTROL STYLE  --------
// --------------------------------

.modal-content {
	overflow: auto;
}

.new-order {
	overflow: hidden;
	position: relative;

	.new-order {
		&__close-btn {
			@include closeButton(40px);
			position: absolute;
			inset: clamp(24px, 2.5vmax, 50px) clamp(24px, 2.5vmax, 50px) auto auto;
			z-index: 10;
		}

		&__btn {
			@include font-style;
			font-size: 22px;
			font-weight: 700;
			padding: 8px 12px;
			border-radius: 20px;
			box-shadow: 0px 3px 6px #00000029;
			width: 100%;
			max-width: 300px;
			max-height: 48px;
			margin: 0;
			border: 1px solid transparent;
			@include transition;
		}

		&__primary-btn {
			color: map-get($colors, 'button-color');
			border-color: map-get($colors, 'border');
			background-color: map-get($colors, 'field-bg');

			&[disabled] {
				color: map-get($colors, 'field-bg');
				border-color: map-get($colors, 'disabled-bg');
				background-color: map-get($colors, 'disabled-bg');
			}
		}
	}
}

// --------------------------------
// ---------  FONT STYLE  ---------
// --------------------------------

.new-order {
	* {
		appearance: none;
		@include font-style;
	}

	padding: {
		top: clamp(12px, 1.5vmax, 28px);
		bottom: clamp(12px, 1.5vmax, 28px);
	}

	.new-order {
		&__container {
			width: 100%;
			flex: 1;
			margin: 0;
			max-height: 100%;
			overflow-y: scroll;
			padding: {
				top: clamp(12px, 1.5vmax, 28px);
				left: clamp(24px, 3vmax, 56px);
				right: clamp(24px, 3vmax, 56px);
				bottom: clamp(12px, 1.5vmax, 28px);
			}

			@include screen-ml {
				&::-webkit-scrollbar {
					width: 8px;
					height: 8px;
				}

				&::-webkit-scrollbar-track {
					background-color: #ddd;
				}

				&::-webkit-scrollbar-thumb {
					background-color: #999;
				}
			}

			@include screen-l {
				&::-webkit-scrollbar {
					width: 12px;
					height: 12px;
				}
			}

			@include screen-xl {
				&::-webkit-scrollbar {
					width: 16px;
					height: 16px;
				}
			}
		}

		&__title {
			@include column;
			align-items: center;
			width: 100%;
			text-align: center;
			font-family: 'Montserrat', sans-serif;
			font-size: 38px;
			line-height: 1.25;
			font-weight: 500;
			letter-spacing: normal;
			color: map-get($colors, 'title');
			grid-column: 1 / -1;
			margin-right: clamp(24px, 5vmax, 100px);
		}

		&__heading {
			padding-left: 24px;
			font-size: 19px;
			font-weight: 700;
			color: map-get($colors, 'heading');
			margin: 0;

			& ~ .new-order__form-group {
				.new-order__form-group__label {
					min-width: auto;
				}
			}
		}
	}
}

// --------------------------------
// ------  FORM GROUP STYLE  ------
// --------------------------------

.new-order {
	&__center {
		justify-content: center;
	}

	&__form-group-fullwidth {
		display: grid;
		grid-template-columns: 155px 0.9fr 0px;
		grid-column-gap: 8px;
		align-items: center;
		position: relative;
		width: 100%;

		&[data-disabled='true'] {
			.table-nav-plus {
				display: none;
			}
		}

		.table-nav-plus {
			position: absolute;
			right: 0;
			margin-left: 40px;
			margin-right: 0;
			@include transition;

			&.table-nav-plus--hidden {
				opacity: 0;
				pointer-events: none;
			}
		}

		// & ~ .new-order__row,
		// & ~ .new-order__2cols {
		// 	padding-right: 72px;
		// }

		.new-order {
			&__form-group {
				&__field {
					width: 100%;
					max-width: 720px;
					margin-left: 8px;
				}
			}
		}
	}

	&__form-group,
	&__radio-group {
		&__label {
			@include transition;

			&:not([data-level]) {
				opacity: 0.5;
			}

			&[data-level] {
				.new-order {
					&__form-group,
					&__radio-group {
						&__label {
							&-pre,
							&-post {
								@include transition;
								opacity: 0.5;
							}
						}
					}
				}
			}

			& > input[type='number'] {
				text-align: center;
				width: 24px;
				height: 24px;
				background-color: transparent;
				border: 1px solid #707070;
				outline: none;
				-moz-appearance: textfield;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
	}

	&__form-group {
		&[data-disabled='true'] {
			pointer-events: none;

			.new-order__form-group__field {
				@include disabledField;
			}

			.new-order__form-group__checkbox {
				filter: grayscale(1);
			}

			&.new-order__form-group-padding {
				opacity: 0.8;
			}
		}

		&,
		&__container {
			@include row;
			position: relative;
			gap: 16px;
		}

		&-padding {
			padding: 2px;
		}

		&__container {
			&-before {
				display: block;
				position: absolute;
				width: 100%;
				text-align: center;
				inset: -100% auto auto;
			}
		}

		&[data-direction='vertical'] {
			.new-order__form-group {
				&__label {
					position: absolute;
					inset: -60% auto auto;
				}
			}
		}

		.new-order__tooltip {
			position: absolute;
			inset: 0 0 auto auto;
			z-index: 5;
			transform: translate(12px, -14px);
			[data-type='tooltip'] {
				&::after {
					width: 280px;
				}
			}
		}

		&__container,
		&__label {
			white-space: nowrap;
			font-size: 12px;
			color: map-get($colors, 'label');
		}

		&__container-before {
			opacity: 0.5;
		}

		&__field {
			min-width: 75px;
			position: relative;
			padding: 8px 10px;
			background-color: map-get($colors, 'field-bg');

			&[data-disabled='true'] {
				@include disabledField;
			}

			textarea {
				resize: none;
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				inset: 100% 0 auto;

				width: 100%;
				height: 1px;
				background-color: map-get($colors, 'border');
			}

			&[data-type='search'] {
				&::before {
					content: '\e90b';
					font-family: 'TiM';
					display: block;
					width: 1em;
					height: 1em;
					position: absolute;
					inset: -4px auto 0 12px;
					margin: auto;
					color: map-get($colors, 'border');
				}

				input {
					padding-left: 24px;
				}

				.select-content {
					padding-left: 32px;
				}
			}
		}

		&__input {
			width: 100%;
			padding: 0;
			margin: 0;
			font-size: 16px;

			&::placeholder {
				opacity: 0.5;
			}

			&[list] {
				&::-webkit-calendar-picker-indicator {
					list-style: none !important;
					margin-right: -5px;
					background-size: 16px 16px;
					background-repeat: no-repeat;
					background-image: url(../../public/assets/images/arrow.png);
				}
			}

			&[type='checkbox'] {
				display: none;

				&:checked + .new-order__form-group__checkbox {
					background-color: map-get($colors, 'checkbox-checked-bg');
					border-color: map-get($colors, 'checkbox-checked-border');
				}
			}
		}

		&__select {
			min-width: 60px;
			width: 100%;

			& > option:disabled[hidden] {
				text-align: center;
			}
		}

		&__checkbox {
			@include column;
			align-items: center;
			width: 30px;
			height: 30px;
			border-radius: 5px;
			background-color: map-get($colors, 'field-bg');
			border: 3px solid map-get($colors, 'heading');
			@include transition;

			& > i {
				font-size: 30px;
				color: map-get($colors, 'field-bg');
			}
		}
	}

	&__radio-group {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 24px;

		&[data-wrap='wrap'] {
			flex-wrap: wrap;
			gap: 12px 24px;
		}

		&__content {
			.new-order {
				&__radio-group {
					gap: 0px 18px;
				}
			}
		}

		&__button,
		&__content {
			@include transition;
		}

		input[type='radio'],
		input[type='checkbox'] {
			display: none;

			&:not(:checked) ~ .new-order__radio-group {
				&__content {
					&[data-hide] {
						display: none;
					}
				}
			}

			&:checked ~ .new-order__radio-group {
				&__button {
					&::before {
						opacity: 1;
					}
				}

				&__label {
					opacity: 1;

					&[data-level] {
						.new-order {
							&__radio-group {
								&__label {
									&-pre,
									&-post {
										opacity: 1;
									}
								}
							}
						}
					}
				}

				&__content {
					opacity: 1;
					pointer-events: all;

					&[data-hide] {
						max-height: 100vmax;
					}
				}
			}
		}

		input[type='radio'] {
			& + .new-order__radio-group__button {
				width: 26px;
				height: 26px;
				min-height: 26px;
				min-width: 26px;
				border-width: 2px;
				border-radius: 50%;
				&::before {
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background-color: map-get($colors, 'heading');
				}
			}
		}

		input[type='checkbox'] {
			& + .new-order__radio-group__button {
				padding: 0;

				&::before {
					content: '\e915';
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					font-family: 'TiM';
					min-width: 16px;
					min-height: 16px;
					width: 16px;
					height: 16px;
					padding: 1px 0 0 1px;
					color: map-get($colors, 'field-bg');
					background-color: map-get($colors, 'checkbox-checked-bg');
				}
			}

			&:checked + .new-order__radio-group__button {
				border-color: map-get($colors, 'checkbox-checked-border');
			}

			&[disabled] {
				& + .new-order__radio-group__button {
					filter: grayscale(1);
				}
			}
		}

		&__button {
			@include column;

			align-items: center;
			padding: 2px;
			min-height: 16px;
			min-width: 16px;
			width: 16px;
			height: 16px;
			border: 1px solid map-get($colors, 'heading');
			border-radius: 4px;

			&::before {
				content: '';
				display: block;
				min-height: 12px;
				min-width: 12px;
				background-color: map-get($colors, 'heading');
				border-radius: 4px;
				opacity: 0;
			}
		}

		&__label {
			color: map-get($colors, 'label');
			letter-spacing: 0.05em;
			font-size: 16px;
			line-height: 1.37;
		}

		&__content {
			display: grid;
			grid-column-gap: 64px;
			grid-row-gap: 24px;
			pointer-events: none;
			opacity: 0;

			&[data-hide] {
				max-height: 0px;
			}

			.calendar[data-type='calendar'] {
				width: fit-content;
			}

			&:not([data-cols]) {
				@include screen-l {
					grid-template-columns: repeat(2, 1fr);
					max-width: 540px;
				}
			}
		}

		&__details {
			@include row;
			gap: 24px;
			opacity: 0.5;

			.new-order__form-group {
				&__label {
					width: 100%;
				}

				&__label,
				&__select {
					text-align: center;
				}
			}
		}

		.checked {
			opacity: 1;
		}
	}
}

// --------------------------------
// ------  CUSTOM ELEMENTS --------
// --------------------------------

.new-order {
	&__form-group,
	&__form-group-fullwidth {
		& > .multiple-select {
			&[data-disabled='true'] {
				@include disabledField;
			}

			.select {
				position: static;
				@include transition;
				&::after {
					transform-origin: center;
				}

				&[data-expand='true'] {
					&::after {
						transform: scaleY(-1);
					}
				}

				&-dropdown-inner {
					padding: 8px;
				}

				&-labels {
					display: grid;
					gap: 12px;
					padding: 20px 0px;
					max-height: 192px;
				}

				&-header {
					&-title {
						color: map-get($colors, 'heading');
						font-size: 16px;
						font-weight: 600;
						padding: 0 0 6px;
					}
				}

				&-option {
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					border: none;
					font-size: 16px;
					padding: 0;
					overflow: hidden;

					&:not([data-show='false']) {
						display: flex;
					}

					input {
						display: none;

						&:checked + .select-option-box {
							color: map-get($colors, 'title');
						}

						&:checked ~ .select-option-text {
							color: map-get($colors, 'heading');
							opacity: 1;
						}
					}

					&-text {
						flex-grow: 1;
						flex-shrink: 0;
						white-space: nowrap;
						text-overflow: ellipsis;
						font-weight: 600;
						color: map-get($colors, 'placeholder');
						opacity: 0.5;
						@include transition;
					}

					&-box {
						font-family: 'TiM';
						flex-shrink: 1;
						display: flex;
						justify-content: center;
						position: relative;
						max-width: 4em;

						color: transparent;
						@include transition;

						&::before {
							content: '\e915';
							display: block;
							position: relative;
							top: -0.1em;
							width: 1em;
							height: 1em;
							min-width: 1em;
							min-height: 1em;
						}
					}
				}
			}
		}
	}
}

// --------------------------------
// -------  CONTENT STYLE  --------
// --------------------------------

.new-order {
	@include column;
	background-color: map-get($colors, 'bg');

	.calendar {
		&__label {
			width: 140px;
		}
	}

	&__row {
		@include row;
		gap: 28px;
		align-items: baseline;
	}

	// &__row,
	// &__2cols {
	// 	justify-content: flex-end;
	// }

	&__form-group-fullwidth,
	&__row,
	&__2cols {
		.new-order {
			&__form-group {
				justify-content: flex-start;

				&__label {
					text-align: right;
					min-width: 120px;
				}

				&:first-child {
					.new-order__form-group {
						&__label {
							min-width: 155px;
						}
						&__field {
							min-width: clamp(80px, 10vw, 190px);
						}
					}
				}
			}
		}
	}

	&__col {
		@include column;
	}

	&__2cols {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;

		.new-order {
			&__form-group {
				&:nth-child(2n + 3) {
					.new-order__form-group {
						&__label {
							min-width: 155px;
						}
					}
				}
				&__field {
					width: 100%;
				}
			}
		}
	}

	&__type {
		display: grid;
		grid-gap: 18px;

		& > label {
			&[data-disabled='true'] {
				.new-order__radio-group {
					&__content {
						.calendar__label {
							@include disabledField;
						}
					}
				}

				&:first-child,
				&:nth-child(2):not([data-selected]) {
					pointer-events: none;
				}

				&:not([data-edit='true']) {
					.new-order__radio-group {
						&,
						label {
							&:not(.new-order__radio-group-edit):not(.new-order__form-group-edit) {
								pointer-events: none;
								filter: grayscale(1);
							}
						}

						&__button,
						&__content input {
							@include disabledField;
						}
					}

					#new-order-iteration-variable-day-container {
						.new-order__radio-group__details {
							.new-order {
								&__radio-group {
									&:first-child {
										filter: grayscale(1);
										pointer-events: none;
									}
								}

								&__form-group {
									&__field {
										@include disabledField;
									}
								}
							}
						}
					}
				}
			}
		}

		& > .new-order__radio-group {
			&:nth-child(2) {
				& > .new-order__radio-group__content {
					align-items: start;

					@include screen-l {
						margin-left: 50px;
						grid-template-rows: 40px 1fr;
					}

					& > .new-order__row {
						&:first-child {
							justify-content: flex-start;
							gap: clamp(16px, 1.75vmax, 32px);
						}
					}
				}
			}
		}

		.new-order {
			&__radio-group {
				&[data-wrap='wrap'] {
					.new-order__radio-group__label {
						flex-basis: 80%;
					}
				}
			}
		}
	}

	&__section {
		@include column;
		gap: 14px;
		max-width: 964px;
	}

	&__layout {
		display: grid;
		grid-template-columns: 1fr minmax(190px, 240px);
		justify-items: center;
		margin: 0 auto;
		grid-column-gap: clamp(24px, 5vmax, 174px);
		grid-row-gap: clamp(16px, 1.75vmax, 32px);

		@include screen-xl {
			grid-template-columns: 1fr minmax(190px, 300px);
		}

		.new-order {
			&__content {
				grid-row: 2;
				max-width: clamp(720px, 60vw, 964px);
			}
			&-controls {
				@include column;
				position: sticky;
				width: 100%;
				height: fit-content;
				grid-row: 2;
				top: clamp(24px, 2.5vmax, 50px);
				//padding-right: clamp(8px, 2vmax, 32px);
				gap: clamp(8px, 2vmax, 32px);

				&__label {
					font-weight: 500;
					font-size: 22px;
				}

				button {
					white-space: nowrap;
				}
			}
		}
	}

	&__content {
		@include column;
		gap: 24px;
	}

	&__customer,
	&__object,
	&__work {
		.new-order__row {
			.new-order__form-group {
				justify-content: flex-start;

				&:first-child {
					width: 100%;

					.new-order__form-group__field {
						width: 100%;
						max-width: 350px;
					}
				}
			}
		}

		.multiple-select {
			.select-option {
				cursor: pointer;
				min-height: 1.35em;
			}
		}
	}

	&__work {
		.multiple-select {
			&[data-open='true'] {
				&::before {
					opacity: 0;
				}
			}
		}
	}

	&__customer {
		.new-order__row {
			.new-order__form-group {
				&:nth-child(2) {
					margin-left: auto;
				}
			}
		}
	}

	&__object {
		.new-order__row {
			width: calc(100% - 67px);
		}
	}

	&__object {
		.new-order__row {
			.new-order__form-group {
				&:first-child {
					.new-order__form-group__field {
						min-width: clamp(80px, 8vw, 200px);
					}
				}
				&:nth-child(2):not(:last-child) {
					margin-left: auto;
				}

				&:not(:first-child) {
					.new-order__form-group__label {
						min-width: clamp(50px, 4vw, 100px);
					}
				}
			}
		}
	}

	&__iteration {
		&__title {
			margin-bottom: 0;
		}

		&__interval {
			@include column;
			justify-content: flex-start;
			gap: 18px;
			min-width: 420px;
			order: 2;

			@include screen-ml {
				grid-column: 2;
				grid-row: 1 / 3;
				order: 0;
			}

			& > .new-order__radio-group {
				&:last-child {
					input:checked ~ .new-order__radio-group__content {
						margin-top: clamp(16px, 1.5vmax, 28px);
					}

					.new-order__radio-group__content {
						margin-left: auto;
						margin-right: auto;

						@include screen-ml {
							margin-left: 0;
							margin-right: 0;
						}
					}
				}
			}
		}

		&__days {
			@include row;
			padding: 14px 0 16px;

			.new-order__radio-group {
				@include column;
				gap: 2px;
			}
		}

		&__variable {
			@include column;
			gap: 16px;

			&-head {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				justify-items: center;
				grid-row-gap: 2px;
				margin: 0 0 0 68px;

				& > span {
					&:first-child {
						grid-row: 2;
					}

					&:nth-child(2) {
						grid-column: 2 / -1;
					}
				}
			}

			.new-order {
				&__radio-group {
					flex-direction: row-reverse;
					gap: 3px;
					min-width: 43px;

					&__details {
						.new-order__form-group__container {
							&:last-child {
								margin-left: 14px;
							}
						}
					}
				}
			}
		}

		&__hours {
			@include column;
			gap: clamp(12px, 1.25vmax, 24px);

			& > .new-order__radio-group {
				&:first-child {
					.new-order__radio-group__details {
						padding-top: 48px;
					}
				}
				&:nth-child(2) {
					input:checked ~ .new-order__radio-group__content {
						margin-top: 16px;
					}
				}
			}
		}
	}

	&__week-calendar {
		display: grid;
		grid-template-rows: repeat(10, 1fr);
		justify-items: center;
		grid-auto-flow: column;
		grid-row-gap: 2px;
		padding: 27px 0px;
		width: clamp(380px, 30vmax, 570px);
		border: 1px solid map-get($colors, 'heading');
		background-color: map-get($colors, 'field-bg');

		& > label {
			display: block;
			cursor: pointer;

			&[data-current] {
				span {
					border-color: map-get($colors, 'close-hover');
				}
			}

			input {
				display: none;

				&:checked + span {
					border-color: map-get($colors, 'week-border');
				}
			}

			&[data-current] {
				span {
					border-color: map-get($colors, 'close-hover');
				}
			}

			span {
				@include column;
				@include transition;
				color: map-get($colors, 'heading');
				font-weight: 700;
				font-size: 19px;
				width: 40px;
				height: 40px;
				min-width: 40px;
				min-height: 40px;
				background-color: transparent;
				align-items: center;
				border: 2px solid transparent;
				border-radius: 50%;
			}
		}
	}
}

// --------------------------------
// ---------  EDIT STYLE  ---------
// --------------------------------
#active-job {
	&[data-edit='false'] {
		.new-order-controls {
			#active-job-save-for-new-order-button {
				display: none;
			}
		}
	}
}
#new-order {
	&[data-edit='false'] {
		.new-order__radio-group-extend {
			&__content {
				display: none;
			}
		}

		.new-order-controls {
			#new-order-save-new-button,
			#new-order-object-groups-button {
				display: none;
			}
		}
	}
	&[data-edit='true'] {
		.new-order__radio-group-extend {
			display: flex;
			gap: 24px;
			align-items: center;
			justify-content: space-between;

			&__content {
				position: relative;

				.new-order__form-group {
					&__text {
						opacity: 0.5;
					}
				}

				.new-order__text-group {
					opacity: 0.5;
					position: absolute;
					min-width: 200px;
					top: 0;
					left: 100%;
					margin-left: clamp(24px, 3.5vmax, 64px);

					&__list {
						@include column;
					}
				}
			}
		}
	}
}

.new-order {
	&__text-group {
		@include column;
		gap: 0.1ch;
	}
}

// --------------------------------
// --------  RESET STYLE  ---------
// --------------------------------

@mixin buttonReset {
	outline: none;
	background: none;
	border: none;
}

.new-order {
	button {
		&:not(.table-nav-plus):not(.new-order__btn) {
			@include buttonReset;
		}
	}

	select,
	input,
	textarea {
		&:not(.calendar__label):not(.calendar__input):not([type='number']) {
			@include buttonReset;
		}
	}
}
