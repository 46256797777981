@mixin headingStyle {
	&[data-head='workday'] {
		color: #000000;
	}

	&[data-head='holiday'] {
		color: #e3383b;
	}

	&[data-head='weeknumber'] {
		color: #aaaaaa;
	}
}

@mixin lastRowStyle {
	&[data-last-row] {
		background-color: transparent;
		pointer-events: none;
	}
}

@mixin holidayStyle {
	&[data-holiday='national'],
	&[data-holiday='local'] {
		background-color: #e3383b4d;
	}

	&[data-holiday] {
		position: relative;

		.calendar__tooltip {
			position: absolute;
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 1rem;
			min-width: 1rem;

			.i-Info {
				position: relative;

				&::after {
					content: '';
					display: block;
					position: absolute;
					inset: 0;
					width: 100%;
					height: 100%;
					background-color: #fff;
					z-index: -1;
					clip-path: circle(25%);
				}
			}
		}
	}

	&[data-holiday='national'] {
		.calendar__tooltip {
			width: 100%;
			height: 100%;
			inset: 0;

			[data-type='tooltip'] {
				display: block;
				width: 100%;
				height: 100%;

				.i-Info {
					display: none;
				}
			}
		}
	}

	&[data-holiday='local'] {
		.calendar__tooltip {
			width: 1rem;
			height: 1rem;
			inset: -4px -2px auto auto;
		}
	}
}

@mixin dayTypeStyle {
	& {
		cursor: pointer;

		outline: 2px solid transparent;
		outline-offset: -2px;
		transition: 180ms ease;

		&:hover {
			outline-color: rgba(143, 191, 248, 0.5);
		}
	}

	&[data-disabled] {
		pointer-events: none;
	}

	&[data-type='weeknumber'] {
		color: #aaaaaa;
	}

	&[data-today] {
		background-color: #79c0e9;
	}

	&[data-type='workday'] {
		color: #000000;

		&[data-disabled] {
			color: #aaaaaa;
		}
	}

	&[data-type='holiday'] {
		color: #e3383b;

		&[data-disabled] {
			color: #e3383b4d;
		}
	}
}

@mixin weekColorScheme {
	&[data-type='workday'] {
		background-color: #def2c7;
	}
	&[data-type='holiday'] {
		background-color: #def2c780;
	}
}

@mixin pastSelectStyle {
	&:not([data-holiday]):not([data-today]):not([data-disabled]) {
		@include weekColorScheme;
	}
}

@mixin futureSelectStyle {
	&:not([data-past]):not([data-holiday]):not([data-today]) {
		@include weekColorScheme;
	}

	&[data-type='holiday'] {
		&[data-disabled] {
			color: #e3383b4d;
		}
	}
}

@mixin calendarPosition {
	&[data-side='right'] {
		.calendar-container {
			right: 0;
		}
	}

	&[data-direction='top'] {
		.calendar-container {
			bottom: 100%;
			min-height: 364px;
		}
	}

	&[data-direction='bottom'],
	&:not([data-direction]) {
		.calendar-container {
			top: 100%;
		}
	}
}

@mixin dayView {
	.calendar {
		&:not([data-mode]) {
			@content;
		}
	}
}

@mixin weekView {
	.calendar {
		&[data-mode='week'] {
			@content;
		}
	}
}

/*
  * Calendar
  */
@include weekView {
	.calendar {
		&__row {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__input {
			text-align: center;
		}

		&__display {
			width: 100%;
			text-align: center;
		}

		&__nav {
			&-left,
			&-right {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 0;
				background-color: transparent;
				border: none;
				outline: none;
			}
		}

		&__table {
			&-body {
				& > tr {
					&[data-selected] {
						.calendar__table-cell {
							&:first-child {
								color: #79c0e9;
							}

							&[data-type='workday'],
							&[data-type='holiday'] {
								border-width: 2px;
								border-style: solid;
								border-color: #79c0e9;

								&:not(:last-child) {
									border-right-color: transparent;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include dayView {
	.calendar {
		&__table-cell {
			&[data-type='weeknumber'] {
				pointer-events: none;
			}

			&[data-selected] {
				outline-color: #79c0e9;
			}
		}
	}
}

.calendar {
	position: relative;
	padding: 0;
	@include calendarPosition;

	&:not([data-past='true']) {
		.calendar {
			&__table-cell {
				@include futureSelectStyle;
			}
		}
	}

	&[data-past='true'] {
		.calendar {
			&__table-cell {
				@include pastSelectStyle;
			}
		}
	}

	&[data-state='false'] {
		.calendar {
			&-container {
				pointer-events: none;
				opacity: 0;
			}
		}
	}

	& > .calendar__label, & .calendar__label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #a9a9a9;

		&::after {
			content: '';
			display: block;
			width: 28px;
			height: 28px;
			background-image: url(../../public/media/Dashboard-Kalender_black.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			opacity: 0.5;
		}
	}
	.calendar {
		&-container {
			position: absolute;
			z-index: 5;

			padding: 16px 22px 12px;
			width: 327px;
			max-height: none;

			line-height: 1.2;
			text-align: center;

			border: 1px solid #707070;
			background-color: var(--bs-white);
			user-select: none;
			transition: 220ms linear;
		}

		&__control {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 1em;
			padding-left: 1em;
			margin-bottom: 1em;
		}

		&__body {
			background-color: #e6e6e6;
			max-height: none;
		}

		&__input,
		&__display,
		&__today-btn,
		&__close-btn {
			font-size: 17px;
			color: #707070;
			border: none;
			outline: none;
			background-color: transparent;
		}

		&__label {
			padding: 6px 8px 4px;
			max-width: 180px;
			background-color: #fff;
		}

		&__input,
		&__display {
			width: 100%;
			font-weight: 700;
			transition: 150ms linear;
		}

		&__input {
			max-width: 120px;

			&:invalid {
				border-bottom-color: var(--bs-danger);
			}
		}

		&__today-btn {
			font-weight: 500;
			white-space: nowrap;
		}

		&__close-btn {
			position: relative;
			width: 1em;
			height: 1em;

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				margin: auto;
				width: 1em;
				height: 3px;
				transform-origin: center;
				background-color: var(--bs-gray-600);
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}

		&__header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 0.5ch;
			position: relative;
			height: 35px;

			padding: 10px;

			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.1em;
		}

		&__month,
		&__year {
			color: #000;
		}

		&__btn {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 9px;

			height: 20px;
			padding: 0;
			width: 20px;

			text-indent: -9999px;

			background: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			overflow: hidden;

			&,
			&:before {
				transition: 180ms linear;
			}

			&:before {
				content: '';

				position: absolute;

				display: block;
				height: 8px;
				width: 8px;

				border-left: 1px solid #000;
				border-top: 1px solid #000;
				transform-origin: center;
			}

			&_prev {
				left: 10px;

				&:before {
					left: 8px;
					transform: rotate(-45deg);
				}
			}

			&_next {
				right: 10px;

				&:before {
					right: 8px;
					transform: rotate(135deg);
				}
			}

			&_disabled {
				pointer-events: none;

				&:before {
					border-color: #aaa;
				}
			}

			&:hover {
				cursor: pointer;

				&:before {
					border-color: #985e5b;
				}
			}
		}

		&__table {
			width: 100%;

			table-layout: fixed;

			&-head {
				background-color: #ebebeb;
			}

			&-body {
				background-color: #f2f2f2;
			}
		}

		&__table-col-header {
			padding: 12px 0;

			font-size: 12px;
			letter-spacing: 0.1em;

			@include headingStyle;
		}

		&__table-col-header,
		&__table-cell {
			width: 35px;
			height: 35px;
		}

		&__table-cell {
			padding: 6px 0;

			color: #1e2633;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.05em;

			@include lastRowStyle;
			@include dayTypeStyle;

			&[data-holiday] {
				@include holidayStyle;
			}

			background-color: #f2f2f2;
		}
	}
}

.form-toggle {
	display: flex;
	align-items: center;
	height: 100%;
	&_checkbox {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + .form-toggle_circle {
			border-color: #009FE3;
		}
		&:checked + .form-toggle_circle + .form-toggle_label {
			color: #009FE3;
		}
		&:checked + .form-toggle_circle:before {
			background-color: #009FE3;
			-webkit-transform: translateX(28px);
			-ms-transform: translateX(28px);
			transform: translateX(28px);
		}
	}
	&_circle {
		position: relative;
		display: flex;
		cursor: pointer;
		width: 62px;
		height: 24px;
		border: 1px solid #B6B8B9;
		border-radius: 24px;
		-webkit-transition: .4s;
		transition: .4s;
		&:before {
			position: absolute;
			content: "";
			height: 36px;
			width: 36px;
			left: -2px;
			bottom: -6px;
			background-color: #B6B8B9;
			border-radius: 50%;
			box-shadow: 0 3px 6px #5FBEE729;
			-webkit-transition: .4s;
			transition: .4s;
		}
	}
	&_label {
		padding-left: 16px;
		font-size: 12px;
		color: #B6B8B9;
	}
}
