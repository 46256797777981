// xs: 375px
// s: 480px
// m: 768
// l: 1280
// xl: 1440
// xxl: 1680

@mixin screen-m {
	@media only screen and (min-width: 768px) {
		@content;
	}
}

@mixin screen-ml {
	@media only screen and (min-width: 1080px) {
		@content;
	}
}

@mixin screen-l {
	@media only screen and (min-width: 1280px) {
		@content;
	}
}

@mixin screen-xl {
	@media only screen and (min-width: 1440px) {
		@content;
	}
}

@mixin screen-xxl {
	@media only screen and (min-width: 1680px) {
		@content;
	}
}
